import React from "react";
import { Row, Typography } from "antd";

const { Text } = Typography;

const StatusLabels = ({ overallExecutionData }) => {
  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        textAlign: "center",
        marginTop: "10px",
        width: "100%",
      }}
    >
      <Text style={{ color: "#8c8c8c", fontSize: "12px" }}>90 days ago</Text>
      <div
        style={{
          flex: 1,
          margin: "0 20px",
          height: "1px",
          backgroundColor: "#8c8c8c",
          alignSelf: "center",
        }}
      />
      <Text style={{ color: "#8c8c8c", fontSize: "12px" }}>
        {overallExecutionData["overall_success_ratio"] != null
          ? `${overallExecutionData["overall_success_ratio"]} % success`
          : "No Executions"}
      </Text>
      <div
        style={{
          flex: 1,
          margin: "0 20px",
          height: "1px",
          backgroundColor: "#8c8c8c",
          alignSelf: "center",
        }}
      />
      <Text style={{ color: "#8c8c8c", fontSize: "12px" }}>Today</Text>
    </Row>
  );
};

export default StatusLabels;
