import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Timeline, Spin, Typography, Button, Popover, Table, Result } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";

import { fetchStatusDetails } from "../api";

const { Title, Text } = Typography;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

const formatHourInterval = (hourString) => {
  const hour = hourString.split(" ")[1];
  return `${hour}:00 - ${hour}:59`;
};

const StatusDetails = () => {
  const { date, projectIdentifier, domain } = useParams();
  const [statusData, setStatusData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [titleKey, setTitleKey] = useState("");
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    setLoading(true);
    setError(false);

    const getTokenAndFetchData = async () => {
      try {
        const data = await fetchStatusDetails(date, projectIdentifier, domain, instance, accounts);
        const firstKey = Object.keys(data)[0];
        const secondKey = Object.keys(data[firstKey])[0];
        setTitleKey(secondKey);
        setStatusData(data[firstKey][secondKey]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching status details:", error);
        setLoading(false);
        setError(true);
      }
    };

    getTokenAndFetchData();
  }, [date, projectIdentifier, domain, instance, accounts]);

  if (loading) {
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  if (error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong with the server."
        extra={
          <Button type="primary" onClick={() => window.location.reload()}>
            Reload Page
          </Button>
        }
      />
    );
  }

  if (!statusData) {
    return (
      <div
        style={{
          backgroundColor: "#121212",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#ffffff",
        }}
      >
        No data available for the specified date, project, and domain.
      </div>
    );
  }

  const renderTimelineItems = () => {
    return statusData.map((execution, index) => (
      <Timeline.Item key={index} color={execution.success_ratio === "1.00" ? "green" : "red"}>
        <Popover
          placement="left"
          content={
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <Table
                dataSource={execution.execution_details.map((detail, index) => ({
                  key: index + 1,
                  execution: (
                    <a href={detail.url} target="_blank" rel="noopener noreferrer">
                      {detail.execution_name}
                    </a>
                  ),
                  status: detail.status === 1 ? "✅" : "❌",
                }))}
                columns={[
                  {
                    title: "Execution",
                    dataIndex: "execution",
                    key: "execution",
                  },
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (status) => <div style={{ textAlign: "center" }}>{status}</div>,
                  },
                ]}
                pagination={false}
                size="small"
              />
            </div>
          }
          title="Related Executions"
        >
          <div>
            <Text strong>{`${formatHourInterval(execution.execution_time)}`}</Text>{" "}
          </div>
          <div>{`Success: ${execution.count_succeeded} | Failures: ${execution.count_failed} | Total: ${execution.total_executions}`}</div>
        </Popover>
      </Timeline.Item>
    ));
  };

  const handleBackToMain = () => {
    navigate(`/${domain}`);
  };

  return (
    <div
      style={{
        backgroundColor: "#121212",
        minHeight: "100vh",
        textAlign: "center",
        padding: "20px",
      }}
    >
      <Button
        type="text"
        color="#1f1f1f"
        icon={<ArrowLeftOutlined />}
        onClick={handleBackToMain}
        style={{ position: "absolute", top: "20px", left: "20px" }}
      ></Button>
      <div style={{ marginTop: "50px" }}>
        <Title level={3} style={{ color: "#ffffff" }}>{`${titleKey} - ${domain} - ${formatDate(
          date
        )} `}</Title>
        <Timeline mode="left" style={{ display: "inline-block" }}>
          {renderTimelineItems()}
        </Timeline>
      </div>
    </div>
  );
};

export default StatusDetails;
