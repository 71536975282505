import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";

const RequireAuth = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect().catch((e) => {
        console.error(e);
      });
    }
  }, [isAuthenticated, inProgress, instance, navigate]);

  return isAuthenticated ? children : null;
};

export default RequireAuth;
