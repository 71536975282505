import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Segmented, Card, Space, Spin, Result, Button } from "antd";
import { useMsal } from "@azure/msal-react";

import StatusOverview from "./StatusOverview";
import { fetchStatus } from "../api";

const MainPage = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { environment } = useParams();
  const [selectedEnvironment, setSelectedEnvironment] = useState(environment || "development");
  const { instance, accounts } = useMsal();

  useEffect(() => {
    setLoading(true);
    setError(false);

    const getTokenAndFetchData = async () => {
      try {
        const data = await fetchStatus(selectedEnvironment, instance, accounts);
        setData(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    };

    getTokenAndFetchData();
  }, [selectedEnvironment, instance, accounts]);

  const handleEnvironmentChange = (value) => {
    setSelectedEnvironment(value);
    navigate(`/${value}`);
  };

  return (
    <div style={{ backgroundColor: "#121212", minHeight: "100vh", padding: "20px" }}>
      <h1 style={{ textAlign: "center", color: "#ffffff" }}>Flyte Status</h1>
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Space direction="vertical" style={{ display: "flex", alignItems: "center" }}>
          <Card style={{ width: 400 }}>
            <Segmented
              block
              options={[
                { label: "Development", value: "development" },
                { label: "Staging", value: "staging" },
                { label: "Production", value: "production" },
              ]}
              value={selectedEnvironment}
              onChange={handleEnvironmentChange}
            />
          </Card>
        </Space>
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <Spin size="large" />
        </div>
      ) : error ? (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong with the server."
          extra={
            <Button type="primary" onClick={() => window.location.reload()}>
              Reload Page
            </Button>
          }
        />
      ) : (
        Object.keys(data).map((client) => (
          <div key={client} style={{ textAlign: "center", marginBottom: "20px" }}>
            <div style={{ display: "inline-block", width: "100%" }}>
              {Object.keys(data[client]).map((project, index, arr) => (
                <StatusOverview
                  key={project}
                  projectName={project}
                  domain={selectedEnvironment}
                  executionData={data[client][project]["executions"]}
                  overallExecutionData={data[client][project]["overall"]}
                  isFirst={index === 0}
                  isLast={index === arr.length - 1}
                />
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default MainPage;
