import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider, theme } from "antd";
import RequireAuth from "./RequireAuth"; // import the RequireAuth component

import MainPage from "./components/MainPage";
import StatusDetails from "./components/StatusDetails";
import "./index.css";

const MainApp = () => (
  <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
    <Router>
      <RequireAuth>
        <Routes>
          <Route path="/:environment?" element={<MainPage />} />
          <Route
            path="/status-details/:date/:projectIdentifier/:domain"
            element={<StatusDetails />}
          />
        </Routes>
      </RequireAuth>
    </Router>
  </ConfigProvider>
);

export default MainApp;
