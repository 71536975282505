import React from "react";
import { Popover, Table } from "antd";
import { orange, red } from "@ant-design/colors";
import { Link } from "react-router-dom";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

const getColor = (successRatio, totalExecutions) => {
  if (totalExecutions === null) return "#3A3A3A";
  if (successRatio >= 1) return "#10a37f";
  if (successRatio >= 0.8) return "#f0de3a";
  if (successRatio >= 0.5) return orange[5];
  return red[5];
};

const StatusBar = ({ executionData, domain }) => {
  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "50%",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      width: "50%",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        gap: "4px",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {executionData.map((execution, index) => (
        <Popover
          key={index}
          content={
            execution.total_executions === null ? (
              <div>No executions on that day</div>
            ) : (
              <div>
                <Table
                  columns={columns}
                  dataSource={[
                    {
                      key: "1",
                      status: "Success",
                      count: execution.count_succeeded || 0,
                    },
                    {
                      key: "2",
                      status: "Failure",
                      count: execution.count_failed || 0,
                    },
                    {
                      key: "3",
                      status: "Total",
                      count: execution.total_executions || 0,
                    },
                  ]}
                  pagination={false}
                  size="small"
                />
                <div style={{ marginTop: "8px", textAlign: "center" }}>
                  <Link
                    to={`/status-details/${execution.execution_date}/${execution.project_identifier}/${domain}`}
                  >
                    View Details
                  </Link>
                </div>
              </div>
            )
          }
          title={`${formatDate(execution.execution_date)}`}
        >
          <div
            style={{
              width: "6px",
              height: "35px",
              borderRadius: "3px",
              backgroundColor: getColor(execution.success_ratio, execution.total_executions),
            }}
          />
        </Popover>
      ))}
    </div>
  );
};

export default StatusBar;
