import React from "react";
import { Typography } from "antd";
import StatusBar from "./StatusBar";
import StatusLabels from "./StatusLabels";

const { Title } = Typography;

const StatusOverview = ({
  projectName,
  executionData,
  domain,
  overallExecutionData,
  isFirst,
  isLast,
}) => {
  return (
    <div
      style={{
        backgroundColor: "#1f1f1f",
        border: "1px solid #434343",
        borderTop: isFirst ? "1px solid #434343" : "0px",
        padding: "10px",
        maxWidth: "900px",
        minWidth: "400px",
        width: "100%",
        margin: "0 auto",
        borderTopLeftRadius: isFirst ? "8px" : "0px",
        borderTopRightRadius: isFirst ? "8px" : "0px",
        borderBottomLeftRadius: isLast ? "8px" : "0px",
        borderBottomRightRadius: isLast ? "8px" : "0px",
        marginTop: "0px",
        color: "#ffffff",
      }}
    >
      <Title level={4} style={{ textAlign: "left", marginBottom: "10px", marginTop: "5px" }}>
        {projectName}
      </Title>
      <StatusBar domain={domain} executionData={executionData} />
      <StatusLabels overallExecutionData={overallExecutionData} />
    </div>
  );
};

export default StatusOverview;
