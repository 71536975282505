import axios from "axios";
import { loginRequest } from "./authConfig";

const API_URL = process.env.REACT_APP_API_URL;

async function getToken(instance, accounts) {
  const response = await instance.acquireTokenSilent({
    ...loginRequest,
    account: accounts[0],
  });
  return response.accessToken;
}

async function fetchStatus(selectedEnvironment, instance, accounts) {
  try {
    const token = await getToken(instance, accounts);
    const apiResponse = await axios.post(
      `${API_URL}/api/status`,
      { domain: selectedEnvironment },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return apiResponse.data;
  } catch (error) {
    console.error("Error in fetchStatus:", error);
    throw error;
  }
}

async function fetchStatusDetails(date, projectIdentifier, domain, instance, accounts) {
  try {
    const token = await getToken(instance, accounts);
    const apiResponse = await axios.post(
      `${API_URL}/api/status-details`,
      { date, project_identifier: projectIdentifier, domain },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return apiResponse.data;
  } catch (error) {
    console.error("Error in fetchStatusDetails:", error);
    throw error;
  }
}

export { fetchStatus, fetchStatusDetails };
